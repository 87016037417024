const init = () => {
    const slideoutMenu = document.querySelector('.page-header-slideout');

    slideoutMenu.removeAttribute('hidden');

    const slideoutOpeners = document.querySelectorAll('[data-open-slideout]');
    slideoutOpeners.forEach((opener) => {
        opener.addEventListener('click', (e) => {
            opener.classList.add('active');
            slideoutMenu.classList.add('active');
        });
    });

    const closeSlideout = () => {
        slideoutMenu.classList.remove('active');
        slideoutOpeners.forEach((link) => {
            link.classList.remove('active');
        });

        slideoutMenu.querySelectorAll('.active').forEach((item) => {
            item.classList.remove('active');
        });
    };

    const slideoutActivators = document.querySelectorAll('[data-open-sub]');
    
    slideoutActivators.forEach((activator) => {
        activator.addEventListener('click', (e) => {
            console.log('click the slideout activator')
            e.preventDefault();
            slideoutMenu.classList.add('active');

            const target = e.target.dataset.openSub;
            const subMenu = document.querySelector(`[data-sub-menu="${target}"]`);
            const level = subMenu.dataset.level;

            const activeSubMenu = document.querySelector(`[data-level="${level}"].active`);
            if(activeSubMenu) {
                activeSubMenu.classList.remove('active');
                activeSubMenu.querySelectorAll('.active').forEach((active) => {
                    active.classList.remove('active');
                });
            }

            document.querySelectorAll('[data-open-sub].active').forEach((link) => {
                link.classList.remove('active');
            });

            const rootSlug = subMenu.dataset.root;
            document.querySelectorAll(`[data-open-sub="${rootSlug}"]`).forEach((link) => {
                link.classList.add('active');
            });
            
            subMenu.classList.add('active');
        });
    });

    const slideoutClosers = document.querySelectorAll('[data-close-sub]');
    slideoutClosers.forEach((closer) => {
        closer.addEventListener('click', (e) => {
            e.preventDefault();
            const target = e.target.dataset.closeSub;
            const subMenu = document.querySelector(`[data-sub-menu="${target}"]`);

            const submenus = subMenu.querySelectorAll('[data-sub-menu].active');
            submenus.forEach((submenu) => {
                submenu.classList.remove('active');
            });

            if(subMenu.dataset.level === '1' && window.innerWidth > 767) {
                closeSlideout();
            }

            subMenu.classList.remove('active');
        });
    });

    const slideoutReset = document.querySelectorAll('[data-reset-sub]');
    slideoutReset.forEach((reset) => {
        reset.addEventListener('click', (e) => {
            const submenus = document.querySelectorAll('[data-sub-menu].active');
            submenus.forEach((submenu) => {
                submenu.classList.remove('active');
            });
            closeSlideout();
        });
    });
}

export default init;
