// crickets
import TransitionInit from './components/transitions';
import AboutTransitionInit from './components/about-transitions';
import HeaderTransitionInit from './components/header-transitions';
import init from './components/modal';
import ReadModalInit from './components/read-more';
import SlideoutInit from './components/slideout-menu';
import FadeSliderInit from './components/fade-slider';

ReadModalInit();
HeaderTransitionInit();
TransitionInit();
AboutTransitionInit();
init();
SlideoutInit();
FadeSliderInit();
