const ReadModalInit = () => {
    const modals = document.querySelectorAll(".modal");
    const modalCloseButtons = document.querySelectorAll(".modal-close");
    const modal = document.getElementById("read-more-modal");
    const modalButton = document.getElementById("read-more");
    if(modal){
      modalButton.addEventListener("click",  function(event){
          modal.classList.add("modal-show");
      });
  
      modalCloseButtons.forEach(elem => {
        elem.addEventListener("click", event => toggleModal(event.currentTarget.closest(".modal").id));
      });
      
      
      // Close Modal with "Esc"...
      document.addEventListener("keydown", event => {
        if(event.keyCode === 27 && document.querySelector(".modal.modal-show")) {
          toggleModal(document.querySelector(".modal.modal-show").id);
        }
      });
      
      function toggleModal(modalId) {
        const modal = document.getElementById(modalId);
      
        if(getComputedStyle(modal).display==="flex") { 
          modal.classList.add("modal-hide");
          setTimeout(() => {
            document.body.style.overflow = "initial";
            modal.classList.remove("modal-show", "modal-hide");
            modal.style.display = "none";      
          }, 200);
        }
        else {
          document.body.style.overflow = "hidden";
          modal.style.display = "flex";
          modal.classList.add("modal-show");
        }
      }    

    }
}

export default ReadModalInit;