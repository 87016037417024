const init = () => {
        if (window.innerWidth > 1024) {
        const obs = new IntersectionObserver(entries => {
    
            const inView = entries.find(e => e.isIntersecting);
    
            if(inView) {
                inView.target.classList.add('in-view');
                if(inView.target.classList.contains('section--snap')) {
                    //inView.target.scrollIntoView({behavior: 'smooth'});
                    inView.target.classList.remove('section--snap');
                    obs.unobserve(inView.target);
                }
            }
        }, {
            rootMargin: `-0px 0px -60px 0px`,
            threshold: 0.0,
        });
    
        document.querySelectorAll('.section--animated').forEach(s => obs.observe(s));
    }
    
}
export default init;
    
