const init = () => {
    $(document).ready(function(){
        var item_length = $('.slide > div').length - 1;
        var slider = $('.slide').slick({
            autoplay: true,
            autoplaySpeed: 2000,
            dots: false,
            infinite: false,
            speed: 1000,
            fade: true,
            slide: 'div',
            cssEase: 'linear'
        });
        
        slider.on('afterChange', function(event, slick, currentSlide, nextSlide){
        if( item_length === slider.slick('slickCurrentSlide') ){
            slider.slickSetOption("autoplay",false,false)
        };
        });
        
        
        });
}

export default init;